import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { IWineryCountRes } from '@/pages/winery/data/wineryModel'
import { getWineryCountByStatus } from '@/remote/winery'

export interface IWineryState {
  WineryCountRes: IWineryCountRes
}

@Module({ dynamic: true, store, name: 'Winery' })
class Winery extends VuexModule implements IWineryState {
  public WineryCountRes = {
    allCount: 0,
    publishedCount: 0,
    unpublishedCount: 0
  }

  @Action
  public async FetchWineryCountByStatus() {
    try {
      const res = await getWineryCountByStatus()
      if (res && res.data && res.data.data) {
        this.SET_WINERY_COUNT_RES(res.data.data)
      }
    } catch {
      console.log('getWineryCountByStatus出错了')
    }
  }

  @Mutation
   SET_WINERY_COUNT_RES(res: IWineryCountRes) {
    this.WineryCountRes = res
  }
}

export const WineryModule = getModule(Winery)
